<template>
  <base-material-card inline class="mb-0" icon="mdi-eraser" :title="$t('Corector')">
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-navigation-drawer permanent>
          <v-list dense nav class="pl-1">
            <v-list-item-group v-model="prefix" mandatory color="primary">
              <template v-for="pre in prefixes">
                <v-list-item :key="pre.value" dense>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(pre.text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-divider v-if="index < reports.length - 1" :key="index"></v-divider> -->
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="12" sm="8" md="9" lg="10">
        <v-autocomplete v-model="field" :label="$t('Câmp')" :items="fields" return-object @change="fieldChanged" />
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field v-model="oldValue" :label="$t('Valoare veche')" clearable />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field v-model="newValue" :label="$t('Valoare nouă')" clearable />
          </v-col>
          <v-col cols="12" class="py-0 d-flex justify-center">
            <v-btn color="primary">{{ $t("Modifică") }}</v-btn>
          </v-col>

          <v-col cols="12" sm="6">
            <table-management
              ref="tableold"
              name="tmcorectorold"
              :table="collection"
              view-type="marc"
              custom-table
              opac
              @fieldsChanged="fieldsOldChanged"
              @select="oldValChanged"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <table-management
              ref="tablenew"
              name="tmcorectornew"
              :table="collection"
              view-type="marc"
              custom-table
              opac
              @fieldsChanged="fieldsNewChanged"
              @select="newValChanged"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>
<script>
import TableManagement from "../../../components/containers/TableManagement"
export default {
  components: {
    TableManagement
  },
  data: () => ({
    path: "",
    icon: "",
    oldValue: "",
    newValue: "",
    field: null,
    collection: "marc_cln",
    fields: [],
    prefix: 0,
    prefixes: [
      { text: "Utilizatori", value: "cln" },
      { text: "Monografii", value: "mon" },
      { text: "Inventare", value: "inv" },
      { text: "Articole", value: "art" },
      { text: "Autorități", value: "ath" },
      { text: "Evenimente", value: "evn" },
      { text: "Furnizori", value: "prv" },
      { text: "RMF", value: "rmf" }
    ]
  }),
  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  watch: {
    prefix() {
      this.$log("prefix ", this.prefixes[this.prefix].value)
      this.field = null
      this.collection = "marc_" + this.prefixes[this.prefix].value
    }
  },
  methods: {
    fieldsOldChanged(e) {
      this.$log("fieldsChanged ")
      this.fields = e
      this.$refs.tableold.headers = []
    },
    fieldsNewChanged(e) {
      this.$refs.tablenew.headers = []
    },
    fieldChanged(e) {
      this.$log("fieldChanged ", e)
      this.$refs.tableold.headers = [{ text: e.text, value: e.value, filterable: true }]
      this.$refs.tablenew.headers = [{ text: e.text, value: e.value, filterable: true }]
    },
    oldValChanged(e) {
      this.$log("oldValChanged ", e)
      if (e.length > 0) {
        this.oldValue = e[0][this.field.value]
      }
    },
    newValChanged(e) {
      this.$log("newValChanged ", e)
      if (e.length > 0) {
        this.newValue = e[0][this.field.value]
      }
    }
  }
}
</script>
